import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'

const NavbarComponent = () => {
    return (
        <Navbar expand="lg" bg="success" variant='dark' >
  <Container>
    <Navbar.Brand href="/">
      <img
        src="/assets/images/logo.png"
        alt='logo'
        height='30'
      />{' '}
      <span className="d-none d-sm-none d-md-inline-block">Bel Toufic</span>
    </Navbar.Brand>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="justify-content-end">
        <Nav.Item ><Nav.Link href="/" className="text-white">Home</Nav.Link></Nav.Item>
        <Nav.Item ><Nav.Link href="/daysale" className="text-white">Sales</Nav.Link></Nav.Item>
        <Nav.Item ><Nav.Link href="/cost" className="text-white">Cost Checker</Nav.Link></Nav.Item>
        <Nav.Item ><Nav.Link href="/PriceModifyer" className="text-white">Price Modifyer</Nav.Link></Nav.Item>
      </Nav>
    </Navbar.Collapse>

  </Container>
</Navbar>
    )
}

export default NavbarComponent

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const DaySaleTableComponent = (props) => {
  const data = React.useMemo(() => props.data, [props.data]);

  if (!Array.isArray(data)) {
    return <div>No data available</div>; // or some other error message
  }

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true
    },
    {
      dataField: 'LBP',
      text: 'LBP',
      sort: true
    },
    {
      dataField: 'USD',
      text: 'USD',
      sort: true
    },
    {
      dataField: 'Fetoura',
      text: 'Fetoura',
      sort: true
    },
    {
      dataField: 'Total_LBP',
      text: 'Total LBP',
      sort: true
    },
    {
      dataField: 'Total_USD',
      text: 'Total USD',
      sort: true,
      style: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }
  ];

  return (
    <Row>
      <Col xs={1}></Col>
      <Col xs={10}>
      <BootstrapTable
        keyField="date"
        data={data}
        columns={columns}
        striped
        hover
        condensed
        cellStyle={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
        sortIndicator={true}
        />
      </Col>      
        <Col xs={1}></Col>
    </Row>
  );
};

export default DaySaleTableComponent;
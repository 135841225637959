import React from "react";
import ChartistGraph from "react-chartist";
import {Card, Row, Col} from "react-bootstrap";

const OneItemCardComponent = (props) => {
    return(
        <>
        <Card className="card-stats">
              <Card.Body className="pl-0">
                <Row>
                  {props.icon ? (
                    <>
                    <Col xs="4">
                      <img style={{ width: '100%' , height: '50%', maxHeight: '10vh'}} src={props.icon} fillColor="green" ></img>
                    </Col>
                    <Col xs="8" className="px-0">
                    <div className="numbers">
                      <p className="card-category">{props.title}</p>
                      <Card.Title as="h4">{props.value}</Card.Title>
                    </div>
                  </Col>
                  </>
                  ) : (
                    <>
                    <Col xs="12" className="px-0">
                    <div className="numbers">
                      <p className="card-category">{props.title}</p>
                      <Card.Title as="h4">{props.value}</Card.Title>
                    </div>
                  </Col>
                    </>
                  )
                   }
                  
                  
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  {props.date}
                </div>
              </Card.Footer>
            </Card>
        </>
    )
}

export default OneItemCardComponent;

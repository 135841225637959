import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DaySaleTableComponent from '../components/DaySaleTableComponent';
import DaySaleGraphComponent from '../components/DaySaleGraphComponent';
import DateRangePickerComponent from '../components/DateRangePickerComponent';
import { Container, Row, Col, Card } from 'react-bootstrap'

const DaySalePage = (props) => {
  const [data, setData] = useState({});
  const from = (new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toLocaleDateString('en-CA').replace(/-/g, "")
  const to = (new Date(new Date().getFullYear(), new Date().getMonth()+ 1 ,0)).toLocaleDateString('en-CA').replace(/-/g, "")
  const [selectedFromDate, setSelectedFromDate] = useState(from);
  const [selectedToDate, setSelectedToDate] = useState(to);

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  useEffect(() => {
    console.log(`From date: ${selectedFromDate}`);
    console.log(`To date: ${selectedToDate}`);

    axios.get('api/sale/byday', {
      params: {
        from:  selectedFromDate,
        to: selectedToDate,
      },
    })
      .then(response => {
        const parsedData = Object.keys(response.data).map((date) => ({
          date,
          LBP: formatter.format(response.data[date].LBP),
          USD: formatter.format(response.data[date].USD || 0),
          Fetoura: formatter.format(response.data[date].Fetoura || 0),
          Total_LBP: formatter.format(response.data[date].Total_LBP || 0),
          Total_USD: formatter.format(response.data[date].Total_USD || 0),
        }));
        setData(parsedData);
      })
      .catch(error => {
        console.error(error);
      });
  }, [selectedFromDate,selectedToDate]);



  return (
    <Container>
    <Row>
      <h1>Sales Data</h1>
      <DateRangePickerComponent setSelectedFromDate={setSelectedFromDate} setSelectedToDate={setSelectedToDate} />
      <DaySaleTableComponent data={data} />
      <Row>
      <DaySaleGraphComponent data={data} label="Total_LBP" />
      <DaySaleGraphComponent data={data} label="Total_USD" />
      </Row>
    </Row>
    </Container>
  );
};

export default DaySalePage;
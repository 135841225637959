import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import {Col} from 'react-bootstrap';

const DaySaleGraphComponent = (props) => {
    const data = React.useMemo(() => props.data, [props.data]);

    if (!Array.isArray(data)) {
      return <div>No data available</div>; // or some other error message
    }

  return (
    <Col xs={5}>
    <LineChart width={500} height={500} data={data}>
      <Line type="monotone" dataKey={props.label} stroke="#8884d8" />
      <XAxis dataKey="date" />
      <YAxis />
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <Tooltip />
    </LineChart>
    </Col>
  );
};

export default DaySaleGraphComponent
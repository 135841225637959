import React from 'react'
import axios from 'axios'
import SearchableTable from '../components/SearchableTable';
import { Navbar, Nav, Container } from 'react-bootstrap'
import { Col, Card } from 'react-bootstrap'

const PriceModifyerPage = (props) => {

    //loading data
    let [loadingData, setLoadingData] = React.useState(true);

    //get data

    let [data, setData] = React.useState([]);

    React.useEffect(() => {

        const getData = async () => {
            await axios.get(props.getAPI)
                .then((response) => {
                    let arr = response.data;
                    var i = 1;
                    var temp = [];
                    arr.map(item => {
                        
                        var barcodes= ""
                        item.barcode.map(barcode => {
                            barcodes+=barcode+"\n"
                        })
                        temp.push(
                            {
                                Name: item.Name,
                                Supplier: item.Supplier,
                                MeasurementUnit: item.MeasurementUnit,
                                Cost: item.Cost,
                                barcode: barcodes
                            })
                        i++
                    })
                    console.log(temp);    
                    setData(temp);
                    setLoadingData(false);
                });
                
        }

        if (loadingData) {
            getData();
        }
    }, []);




    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark">
            <Container>
                <Nav className="justify-content-end">
                    <Nav.Item ><Nav.Link href="/PriceModifyer">Item Select</Nav.Link></Nav.Item>
                    <Nav.Item ><Nav.Link href="/PriceModifyerList">List</Nav.Link></Nav.Item>
                </Nav>

            </Container>
        </Navbar>
            <Col sm={12} md={12} lg={8} className="my-2 mx-auto">
                
                        <div className="card-title text-center h3" style={{ "font-family": "Georgia" }}>Item Select</div>
                    
                  
                        {loadingData ? (
                            <p>Loading Please wait...</p>
                        ) : (
                            <SearchableTable data={data} pageSize={30} pageCount={data.length/30} />
                        )}
                    

            </Col >
        </>
    )
}

export default PriceModifyerPage

import React from 'react';
import { useState } from 'react';
import Form from "react-bootstrap/Form";
import { Row, Col, Card } from 'react-bootstrap'

const DateRangePickerComponent = (props) => {
  const [selectedFromDate, setSelectedFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [selectedToDate, setSelectedToDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

  const handleFromDateChange = (dateString) => {
    const newDate = new Date(dateString);
    setSelectedFromDate(newDate);
    props.setSelectedFromDate(newDate.toLocaleDateString('en-CA').replace(/-/g, ""));
    console.log(`Selected date: ${newDate.toLocaleDateString('en-CA')}`);
  };

  const handleToDateChange = (dateString) => {
    const newDate = new Date(dateString);
    setSelectedToDate(newDate);
    props.setSelectedToDate(newDate.toLocaleDateString('en-CA').replace(/-/g, ""));
    console.log(`Selected date: ${newDate.toLocaleDateString('en-CA')}`);
  };

  return (
    <Row>
      <Col xs={1}></Col>
      <Col xs={5}>
      <Form>
      <Form.Control
        type="date"
        value={selectedFromDate.toLocaleDateString('en-CA')}
        onChange={(e) => handleFromDateChange(e.target.value)}
      />
    </Form>   
      </Col>
      <Col xs={5}>
    <Form>
      <Form.Control
        type="date"
        value={selectedToDate.toLocaleDateString('en-CA')}
        onChange={(e) => handleToDateChange(e.target.value)}
      />
    </Form> 
    </Col>
    </Row>  

  );
};

export default DateRangePickerComponent;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChartistGraph from "react-chartist";
import LineGraphComponent from "../components/Cards/LineGraphComponent";
import PieChartComponent from "../components/Cards/PieChartComponent";
import OneItemCardComponent from "../components/Cards/OneItemCardComponent";
import TasksComponent from "../components/Cards/TasksComponent";
import BarGraphComponent from "../components/Cards/BarGraphComponent";
import DateRangePickerComponent from '../components/DateRangePickerComponent';
import moment from 'moment'

import svg_usd from "../assets/svg/usd.svg";
import svg_ll from "../assets/svg/ll.svg";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";



const Home = ()=>{
  const [total_LBP,setTotal_LBP] = useState(0)
  const [total_USD,setTotal_USD] = useState(0)
  const [khodra,setKhodra] = useState(0)
  const [data, setData] = useState({});
  const [lastDate, setlastDate] = useState({});
  const from = (new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toLocaleDateString('en-CA').replace(/-/g, "")
  const to = (new Date(new Date().getFullYear(), new Date().getMonth()+ 1 ,0)).toLocaleDateString('en-CA').replace(/-/g, "")
  const [selectedFromDate, setSelectedFromDate] = useState(from);
  const [selectedToDate, setSelectedToDate] = useState(to);

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  useEffect(() => {
    console.log(`From date: ${selectedFromDate}`);
    console.log(`To date: ${selectedToDate}`);

    axios.get('api/sale/byday', {
      params: {
        from:  selectedFromDate,
        to: selectedToDate,
      },
    })
      .then(response => {
        const parsedData = Object.keys(response.data).map((date) => ({
          date,
          LBP: formatter.format(response.data[date].LBP),
          USD: formatter.format(response.data[date].USD || 0),
          Fetoura: formatter.format(response.data[date].Fetoura || 0),
          Total_LBP: formatter.format(response.data[date].Total_LBP || 0),
          Total_USD: formatter.format(response.data[date].Total_USD || 0),
        }));
        setData(parsedData);
      })
      .catch(error => {
        console.error(error);
      });
  }, [selectedFromDate,selectedToDate]);

useEffect(()=>{
  const dates = Object.keys(data);
  axios.get('api/sale/lastday')
    .then(response => {
      const parsedData = Object.keys(response.data).map((date) => ({
        date,
        LBP: formatter.format(response.data[date].LBP),
        USD: formatter.format(response.data[date].USD || 0),
        Fetoura: formatter.format(response.data[date].Fetoura || 0),
        Total_LBP: formatter.format(response.data[date].Total_LBP || 0),
        Total_USD: formatter.format(response.data[date].Total_USD || 0),
        khodra: formatter.format(response.data[date][24].sum || 0),
      }));
      setlastDate(parsedData[0]);
      console.log(parsedData[0]);
    })
    .catch(error => {
      setlastDate({});
    });

},[])

  return (
    <>
      <Container fluid>
        <Row>
          <Card>
          <DateRangePickerComponent setSelectedFromDate={setSelectedFromDate} setSelectedToDate={setSelectedToDate} />
          </Card>
        </Row>
        <Row>
          <Col lg="3" xs="6">
            <OneItemCardComponent icon={svg_usd} title="Total USD" value={lastDate.Total_USD} date={moment(lastDate.date).format('dddd DD MMM YYYY')} />
          </Col>
          <Col lg="3" xs="6">
          <OneItemCardComponent icon={svg_ll} title="Total LBP" value={lastDate.Total_LBP} date={moment(lastDate.date).format('dddd DD MMM YYYY')} />
          </Col>
          <Col lg="3" xs="6">
          <OneItemCardComponent  title="Khodra" value={lastDate.khodra} date={moment(lastDate.date).format('dddd DD MMM YYYY')} />
          </Col>
        </Row>
        <Row>
          <Col md="8">
          <LineGraphComponent/>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <BarGraphComponent/>
          </Col>
          <Col md="6">
            {/* <TasksComponent/> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home

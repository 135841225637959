import React from 'react'
import Pagination from '@material-ui/lab/Pagination';
import { Table, Card, Row, Col, FormControl } from 'react-bootstrap'
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table'
import {Button} from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close'

function SearchableTable(props) {
  const data = React.useMemo(() => props.data);

  const columns = React.useMemo(() => (
      [
          {
              Header: 'Name',
              accessor: 'Name',
              minWidth:200
          },
          {
            Header: 'Barcode',
            accessor: 'bacrode',
            width:30
        },
          {
              Header: 'Cost',
              accessor: 'Cost',
              maxWidth: 100
          },
          {
              Header: 'Margin',
              accessor: 'Margin',
              maxWidth: 100
          },
          {
              Header: 'Price',
              accessor: 'Price',
              width: 30          
          }
      ]
  ), [])



  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      setGlobalFilter,
      state: { pageIndex, pageSize, globalFilter }
  } = useTable(
      {
          columns,
          data,
          initialState: {
              pageSize: props.pageSize,
              sortBy: [
                  {
                      id: 'name',
                      desc: false
                  }
              ]
          }
      },
      useGlobalFilter, usePagination)

  let [pageNumber, setPageNumber] = React.useState(1);
  const handleChange = (event, value) => {
      setPageNumber(value)
      gotoPage(value - 1)
  }

  return (
      <>

          <Row className="my-2 "><Col xs={6} md={4} lg={8}><FormControl placeholder={"Search"} value={globalFilter || ""} onChange={e => setGlobalFilter(e.target.value)} /></Col><Col xs={1} md={1} lg={1}><Button variant="light"><CloseIcon fontSize="large" color="primary" onClick={e => setGlobalFilter(" ")}/></Button></Col></Row>
          <Table className="mx-auto" striped bordered hover {...getTableProps()}>
              <thead>
                  {headerGroups.map(group => (
                      <tr {...group.getHeaderGroupProps()}>
                          {group.headers.map(column => (
                              <th {...column.getHeaderProps({style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth, text: "wrap" }})}>
                                  {column.render("Header")}

                              </th>
                          ))}
                      </tr>
                  ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <>
                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    
                                    </>
                                );
                            })}
                        <td><Button onClick={() =>{console.log(row.cells[0].value)}}>Add to List</Button></td>
                        </tr>
                    )
                  })}
              </tbody>
          </Table>



          <Card.Footer> <Pagination page={pageNumber} count={pageCount} onChange={handleChange} color="primary" /></Card.Footer>


      </>
  )
}



export default SearchableTable;
